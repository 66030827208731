<template>
  <div>
    <v-card flat>
      <v-toolbar flat><h3>Leave Settings</h3></v-toolbar>
      <div class="font-weight-medium ml-4">
        Calendar Year (January to December)
      </div>

      <div class="ml-4 mt-2">Configure leave for your organization</div>
      <v-card-text>
        <v-row class="mt-5 ml-2">
          <v-col cols="12" md="6" lg="6" xs="12" xl="6" sm="12">
            <v-card>
              <v-form ref="form">
                <v-row class="mt-n5">
                  <v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      label="Leave Name"
                      class="ml-4"
                      v-model="corection_data"
                      :rules="rules_scan_anywhere"
                    />
                  </v-col>
                  <!-- <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      :items="duartionitem"
                      label="Select"
                      class="ml-n4"
                      v-model="typeLeave"
                    />
                  </v-col> -->
                  <v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      class="ml-n4"
                      v-model="noOFDays"
                      :rules="number_rules"
                      :label="
                        typeLeave == 'Days' ? 'No of Days' : 'No of Months'
                      "
                    />
                  </v-col>

                  <v-col cols="1">
                    <v-btn
                      x-small
                      @click="add_action(corection_data)"
                      small
                      color="primary"
                      class="white--text mt-2 ml-n7"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-card-text>
                <v-data-table
                  :height="height"
                  :fixed-header="fixed"
                  :headers="headers"
                  :items="tabelitems"
                  :loading="tableLoading"
                  :items-per-page="10"
                  :sort-by="['designation_name']"
                  dense
                  class="mt-n5"
                  hide-default-footer
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="open_delete(item)" color="red"
                      >mdi-delete</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  depressed
                  :loading="loading"
                  @click="save_mutation()"
                  dark
                  class="gradient-bg white--text text-capitalize mt-2"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="leavedeletion" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Delete Leave type</v-toolbar-title><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Delete {{ deleteObject.type }} ?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="delet_leave(deleteObject)"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
  </div>
</template>
  
  <script>
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { master_leave_settings } from "@/graphql/queries.js";
import Snackbar from "@/components/SnackBar.vue";
export default {
  components: {
    Snackbar,
    Overlay,
  },
  data() {
    return {
      SnackBarComponent: {},
      deleteObject: {},
      overlay: false,
      typeLeave: "Days",
      leavedeletion: false,
      corection_data: "",
      leavecount: "",
      number_rules: [
        (v) => !!v || "required ",
        (value) => /^\d+$/.test(value) || "Only numbers are allowed",
      ],
      rules_scan_anywhere: [
        (v) =>
          (typeof v === "string" && v.trim().length !== 0) ||
          " Leave Name cannot be empty",
        (v) =>
          /^[a-z\sA-Z]{1,30}$/.test(v) ||
          "Leave Name cannot contain special characters ",
      ],
      noOFDays: "",
      duartionitem: ["Days", "Months"],
      height: 0,
      tabelitems: [],
      tableLoading: false,
      loading: false,
      fixed: true,
      headers: [
        {
          text: "Leave Name",
          value: "type",
          sortable: false,
        },
        {
          text: "Days",
          value: "days",
          sortable: false,
        },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.height = window.innerHeight - 390;
    this.get_leave();
  },
  methods: {
    close_dialog() {
      this.leavedeletion = false;
    },

    open_delete(item) {
      this.deleteObject = item;
      this.leavedeletion = true;
    },
    delet_leave(item) {
      for (let i = 0; i < this.tabelitems.length; i++) {
        if (item.type == this.tabelitems[i].type) {
          this.tabelitems.splice(i, 1);
        }
      }
      this.leavedeletion = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Leave Deleted Please Save",
      };
    },
    add_action(corection_data) {
      if (this.$refs.form.validate()) {
      
        var key_object = {
          type: corection_data,
          days: this.noOFDays,
          duration_type: this.typeLeave,
          duration_value: this.noOFDays,
        };

        var hasMatchingCode = this.tabelitems.some(
          (obj) => obj.type === corection_data
        );
        if (hasMatchingCode == false) {
          this.tabelitems.push(key_object);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Leave already exists",
          };
        }
        this.corection_data = "";
        (this.noOFDays = ""), this.$refs.form.resetValidation();
      }
    },

    async save_mutation() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(master_leave_settings, {
            input: {
              action_type: "CREATE",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              leave_types: JSON.stringify(this.tabelitems),
              total_leaves_for_year: +this.leavecount,
            },
          })
        );
        var response = JSON.parse(result.data.master_leave_settings);
        if (response.Status == "SUCCESS") {
          this.get_leave();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: response.Message,
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.Message,
          };
        }
    
        this.loading = false;
      } catch (err) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        console.log(err);
        this.loading = false;
      }
    },
    async get_leave() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(master_leave_settings, {
            input: {
              action_type: "GET",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        var response = JSON.parse(result.data.master_leave_settings);
        this.overlay = false;
        if (response.Status == "SUCCESS") {
          this.leavecount =
            response.data[0].total_leaves_for_year == 0
              ? ""
              : response.data[0].total_leaves_for_year;
          // this.tabelitems = response.data[0].leave_types;
          this.tabelitems =
            response.data[0].leave_types == undefined
              ? []
              : response.data[0].leave_types;
        }
      } catch (err) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        console.log(err);
      }
    },
  },
};
</script>
  
  <style>
</style>